import React, { useEffect, useState } from 'react';

import {
	FaqItemMultiLang,
	Post,
	Tag,
	FaqItem,
	BookieRatingItem,
	CasinoRatingItem,
} from '@ui-kit/main-api-types';
import { PostsWidget } from '@ui-kit/main-frontend-ui-kit/dist/src/components/Posts-widget';
import {
	getTagTitle,
	sendAnalytics,
} from '@ui-kit/main-frontend-ui-kit/dist/src/utils/helpers';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';

import styles from './PostList.module.scss';

import RBWidgetHOC from '@components/RBWidgetHOC';
import UiButton from '@components/Ui-button';
import { IRbWidgetLinks } from '@utils/bookie';
import { POSTS_PAGE_SIZE, TRIBUNA_COM_URL } from '@utils/constants';
import { shouldShowAdvertisementForPostsDependsOfDevice } from '@utils/helpers';
import { LANG } from '@utils/types';

import PostCardHOC from './PostCardHOC';

const CustomRatingsWidgetHOC = dynamic(
	() => import('@components/CustomRatingsWidgetHOC'),
	{
		ssr: true,
	},
);
const FAQ = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/FAQ').then(
			m => m.FAQ,
		),
	{
		ssr: true,
	},
);
const BetweenContentBanner = dynamic(
	() => import('@components/Banner-between-content'),
	{
		ssr: false,
	},
);
const NoSSRHOC = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/NoSSRHOC').then(
			m => m.NoSsr,
		),
	{
		ssr: false,
	},
);

const Pagination = dynamic(
	() =>
		import('@ui-kit/main-frontend-ui-kit/dist/src/components/Pagination').then(
			m => m.Pagination,
		),
	{
		ssr: true,
	},
);

const StructuredBodyHOC = dynamic(
	() =>
		import(
			'@ui-kit/main-frontend-ui-kit/dist/src/components/StructuredBodyHOC'
		).then(m => m.StructuredBodyHOC),
	{
		ssr: true,
	},
);

interface IPostListProps {
	initialPosts: Post[];
	getMorePosts?: () => Promise<{
		posts: Post[];
		isLastPage: boolean;
		page?: number;
	}>;
	showBlog?: boolean;
	showEditButton?: boolean;
	lastPage?: number;
	initialPage?: number;
	basicRoute?: string;
	faq?: FaqItemMultiLang[] | FaqItem[];
	hruForFaqAnalytics?: string;
	isPaginationDisplayed?: boolean;
	isAutoLoadingTurnedOn?: boolean;
	isItMainPage?: boolean;
	seoText?: string[];
	tag?: Tag;
	hasAdditionalPageParams?: boolean;
	displayAdvertisement?: boolean;
	desktopAdvertisementInterval?: number;
	mobileAdvertisementInterval?: number;
	rbWidgetLinks?: IRbWidgetLinks;
	displayBookieWidget?: boolean;
	displayPostsWidget?: boolean;
	popularNotEditorialPosts?: Post[];
	displayCRWidget?: boolean;
	customRatings?: BookieRatingItem[] | CasinoRatingItem[];
	isBookie?: boolean;
	sportPath?: string;
}

export const PostList: React.FC<IPostListProps> = ({
	initialPosts,
	getMorePosts = () => ({ posts: [], isLastPage: false, page: 1 }),
	showBlog = true,
	showEditButton = false,
	lastPage,
	initialPage = 1,
	basicRoute,
	isPaginationDisplayed = false,
	faq = [],
	hruForFaqAnalytics = '',
	isAutoLoadingTurnedOn = false,
	isItMainPage = false,
	seoText,
	tag,
	hasAdditionalPageParams = false,
	displayAdvertisement = true,
	desktopAdvertisementInterval,
	mobileAdvertisementInterval,
	rbWidgetLinks,
	displayBookieWidget,
	displayPostsWidget,
	popularNotEditorialPosts,
	displayCRWidget,
	customRatings,
	isBookie,
	sportPath,
}) => {
	const { locale } = useRouter();

	const [showLoadMoreButton, setShowLoadMoreButton] = useState(
		lastPage !== initialPage && !!initialPosts.length,
	);
	const [posts, setPosts] = useState(initialPosts);
	const [currentPage, setCurrentPage] = useState(initialPage);

	const t = useTranslations('translations');
	const onLoadMoreClick = async () => {
		const { posts: newPosts = [], isLastPage, page } = await getMorePosts();
		setShowLoadMoreButton(!isLastPage);
		setPosts([...posts, ...newPosts]);
		setCurrentPage(page);

		sendAnalytics({
			category: 'show_more',
			name: 'view',
			label: 'posts',
		});
	};
	const [isLoaded, setIsLoaded] = useState(false);

	const hasFaqOnCurrentLocale = !!faq?.[0]?.title?.[locale];
	const shouldAddFAQWidget =
		!!faq?.length && hasFaqOnCurrentLocale && !hasAdditionalPageParams;

	useEffect(() => {
		setPosts(initialPosts);
	}, [initialPosts]);

	useEffect(() => {
		setIsLoaded(true);
	}, []);

	const linkToBlog = `${TRIBUNA_COM_URL}${locale}/${
		sportPath ? `${sportPath}/blogs` : 'blogs'
	}/`;

	return (
		<div className={styles['post-list']}>
			<div className={styles['post-list__list']}>
				{posts?.map((blog, index) => {
					const shouldDisplayAdvertisement =
						displayAdvertisement &&
						shouldShowAdvertisementForPostsDependsOfDevice({
							index,
							startingElementIndex: 2,
							desktopAdvertisementInterval,
							mobileAdvertisementInterval,
						});
					const shouldShowFAQ =
						shouldAddFAQWidget &&
						(index === 18 ||
							(index === posts?.length - 1 && posts?.length - 1 < 18));
					const shouldShowSeoText =
						!!seoText?.length &&
						!hasAdditionalPageParams &&
						(index === 18 ||
							(index === posts?.length - 1 && posts?.length - 1 < 18));
					return (
						<div
							className={`${styles['post-list__post-wrapper']}`}
							key={blog.id}
							data-test={isItMainPage ? 'main-post-item' : null}
						>
							<PostCardHOC
								post={blog}
								showBlog={showBlog}
								showEditButton={showEditButton}
							/>
							<NoSSRHOC hideFromBot>
								{shouldDisplayAdvertisement && <BetweenContentBanner />}
							</NoSSRHOC>
							{index === 3 && displayCRWidget && (
								<div className={styles['post-list__cr-widget']}>
									<CustomRatingsWidgetHOC
										ratings={customRatings}
										isVertical={false}
										isBookie={isBookie}
										isInCentralColumn
									/>
								</div>
							)}
							{index === 3 &&
								displayPostsWidget &&
								!!popularNotEditorialPosts?.length && (
									<PostsWidget
										widgetDataTest="best-post-section"
										headerDataTest="best-post-section-header"
										buttonDataTest="best-post-section-button-all"
										localeLang={locale as LANG}
										posts={popularNotEditorialPosts}
										linkToBlog={linkToBlog}
										localeLink={`${TRIBUNA_COM_URL}${locale}`}
										style={{
											marginTop: !isItMainPage ? '10px' : '20px',
											marginBottom: isItMainPage ? '20px' : '0',
										}}
									/>
								)}
							{index === 3 && displayBookieWidget && (
								<RBWidgetHOC
									rbWidgetLinks={rbWidgetLinks}
									className={styles['post-list__rb-widget']}
								/>
							)}
							{shouldShowSeoText && (
								<div className={styles['post-list__seo-text']}>
									<StructuredBodyHOC
										seoText={seoText}
										alt={getTagTitle({ tag, lang: locale })}
									/>
								</div>
							)}
							{shouldShowFAQ && (
								<div className={styles['post-list__faq']}>
									<FAQ
										data={faq}
										locale={locale}
										buttonName={t('watch-all')}
										firstShowLimit={5}
										tagId={hruForFaqAnalytics}
									/>
								</div>
							)}
						</div>
					);
				})}
			</div>
			{showLoadMoreButton && (
				<UiButton
					onClick={onLoadMoreClick}
					isAutoLoadingTurnedOn={isAutoLoadingTurnedOn}
					data-post-load-more-button
				>
					{t('show-more')}
				</UiButton>
			)}
			{isPaginationDisplayed && lastPage > 1 && (
				<Pagination
					className="pagination-bar"
					currentPage={currentPage}
					pageSize={POSTS_PAGE_SIZE}
					rootLink={`${basicRoute}page`}
					lastPage={lastPage}
					isLoaded={isLoaded}
				/>
			)}
		</div>
	);
};

export default PostList;
